<template>
    <div class="user">
        <el-table ref="mainTable" :data="dataList" style="width: 100%" stripe border>
            <el-table-column prop="avatar" label="头像" width="80" header-align="center">
                <template slot-scope="scope">
                    <el-avatar :size="50" :src="scope.row.avatar"></el-avatar>
                </template>
            </el-table-column>
            <el-table-column prop="nickname" label="昵称" header-align="center"></el-table-column>
            <el-table-column prop="mobile" label="手机号码" header-align="center"></el-table-column>
            <el-table-column prop="orderCount" label="订单数" align="center"></el-table-column>
            <el-table-column prop="collectCount" label="收藏数" align="center"></el-table-column>
            <el-table-column prop="createTime" label="注册日期" align="center"></el-table-column>
            <el-table-column prop="lastLogin" label="最近登录" align="center"></el-table-column>
            <el-table-column fixed="right" label="操作" width="150" align="center">
                <template slot-scope="scope">
                    <el-button @click="handleClick('order', scope.row)" type="text" size="small">查看订单</el-button>
                    <el-button @click="handleClick('deliver', scope.row)" type="text" size="small">收货地址</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-dialog :visible.sync="dialogVisible" title="收货地址">
            <table class="itemDetail" style="width:100%;border-left:#e8ebf3 1px solid;border-top:#e8ebf3 1px solid;">
                <tr>
                    <th>姓名</th>
                    <th>手机号码</th>
                    <th>地址</th>
                </tr>
                <tr v-for="(item, index) in userAddressList" :key="index">
                    <td>{{ item.fullName }}</td>
                    <td>{{ item.mobile }}</td>
                    <td>{{ item.province }}-{{ item.city }}-{{ item.district }}-{{ item.address }}</td>
                </tr>
            </table>
        </el-dialog>
        <el-pagination v-if="totalCount > pageSize" class="pt" background layout="prev, pager, next" @current-change="changePage" :page-size="pageSize" :total="totalCount"> </el-pagination>
    </div>
</template>
<script>
export default {
    data() {
        return {
            pageNo: 1,
            pageSize: 10,
            totalCount: 0,
            dataList: [],
            dialogVisible: false,
            userAddressList: [],
        };
    },
    mounted() {
        this.loadDataList();
    },
    methods: {
        formatter(row, column, value) {
            return value;
        },
        loadDataList: function() {
            this.$http({
                url: "/sys/operation/userList",
                data: {
                    pageNo: this.pageNo,
                    pageSize: this.pageSize,
                },
            }).then((res) => {
                this.totalCount = parseInt(res.data.total);
                this.dataList = res.data.records;
            });
        },
        handleClick: function(action, row) {
            // 标记发货
            if ("deliver" == action) {
                this.$http({
                    url: "/sys/operation/userAddressList",
                    data: {
                        userId: row.id
                    },
                }).then((res) => {
                    this.userAddressList = res.data;
                    this.dialogVisible = true;
                });
            }
            // 填写物流单号
            if ("order" == action) {
                this.$router.push("/order/list?userId=" + row.id);
            }
        },
        // 翻页
        changePage: function(value) {
            this.pageNo = value;
            this.loadDataList();
        },
    },
};
</script>
<style>
.itemDetail td, .itemDetail th{
    border-right: #e8ebf3 1px solid;
    border-bottom: #e8ebf3 1px solid;
    padding: 5px;
}
</style>
